/************************
 * NUEVO TIPO DE VISITA *
 ************************/

// Color picker.
(function() {
    // Bundle: https://github.com/jimigrunge/bootstrap-colorpicker-bundle
    // DOCs: https://farbelous.github.io/bootstrap-colorpicker/index.html
    $(document).ready(function() {
        $('#color_picker').colorpicker({
            format: 'hex',
            align: 'left',
            sliders: {
                saturation: {
                    maxLeft: 100,
                    maxTop: 100,
                    callLeft: 'setSaturation',
                    callTop: 'setBrightness'
                },
                hue: {
                    maxLeft: 0,
                    maxTop: 100,
                    callLeft: false,
                    callTop: 'setHue'
                }
            }
        });
    });
})();

// Añadir horario
(function() {
    var $collectionHolder;

    // setup an "add a tag" link
    var $addLink = $('<a href="#" class="add_horarios_link">Añadir horario</a>');
    var $newLinkLi = $('<li></li>').append($addLink);

    $(document).ready(function() {
        // Get the ul that holds the collection of tags
        $collectionHolder = $('ul.horarios');

        // add the "add a tag" anchor and li to the tags ul
        $collectionHolder.append($newLinkLi);

        // count the current form inputs we have (e.g. 2), use that as the new
        // index when inserting a new item (e.g. 2)
        $collectionHolder.data('index', $collectionHolder.find(':input').length);

        $addLink.on('click', function(e) {
            // prevent the link from creating a "#" on the URL
            e.preventDefault();

            // add a new tag form (see next code block)
            addHorarioForm($collectionHolder, $newLinkLi);
        });
    }); // end ready

    function addHorarioForm($collectionHolder, $newLinkLi) {
        // Get the data-prototype explained earlier
        var prototype = $collectionHolder.data('prototype');

        // get the new index
        var index = $collectionHolder.data('index');

        var newForm = prototype;
        // You need this only if you didn't set 'label' => false in your tags field in TaskType
        // Replace '__name__label__' in the prototype's HTML to
        // instead be a number based on how many items we have
        // newForm = newForm.replace(/__name__label__/g, index);

        // Replace '__name__' in the prototype's HTML to
        // instead be a number based on how many items we have
        newForm = newForm.replace(/__name__/g, index);

        // increase the index with one for the next item
        $collectionHolder.data('index', index + 1);

        // Display the form in the page in an li, before the "Add a tag" link li
        var $newFormLi = $('<li></li>').append(newForm);
        $newLinkLi.before($newFormLi);
    }
})();


/*********************
 * GESTIONAR VISITAS *
 *********************/

// eonasdan-bootstrap-datetimepicker
(function() {
    $(document).ready(function() {
        if (0 == $('#generarVisitas').length) {
            return;
        }

        var options = {
            useCurrent: false,
            viewMode: 'days',
            format: 'DD/MM/YYYY',
            minDate: minDate,
            maxDate: maxDate,
            locale: 'es',
            icons: {
                time: 'fa fa-clock',
                date: 'fa fa-calendar-alt',
                up: 'fa fa-chevron-up',
                down: 'fa fa-chevron-down',
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-crosshairs',
                clear: 'fa fa-trash-alt',
                close: 'fa fa-times-circle'
            }
        };

        $('#fechaInicio').datetimepicker(options);
        $('#fechaFin').datetimepicker(options);

        /*$('#fechaInicio, #fechaFin').click(function(event) {
            if ($('#calendarioContainer').css('display') != 'block' || modificar) {
                return;
            }

            event.preventDefault();
            modificar = confirm('Si modificas la fecha perderás los cambios realizados en el calendario.');
        });*/

        $('#fechaInicio, #fechaFin').on('dp.change', function(event) {
            setDates(this.id);

            var disabled = ($('#fechaInicio').val() === '' || $('#fechaFin').val() === '');
            $('#generarCalendario').prop('disabled', disabled);
        });

        function setDates(changedId) {
            fechaInicio = $('#fechaInicio').datetimepicker('date');
            fechaFin    = $('#fechaFin').datetimepicker('date');

            if (changedId == 'fechaInicio') {
                $('#fechaFin').data('DateTimePicker').minDate(fechaInicio);
            } else {
                var today = moment().millisecond(0).second(0).minute(0).hour(0);
                $('#fechaInicio').data('DateTimePicker').minDate(today);
                $('#fechaInicio').data('DateTimePicker').maxDate(fechaFin);
            }

            $('#fechaInicio').data('DateTimePicker').locale('es');
            $('#fechaFin').data('DateTimePicker').locale('es');
        }
    });
})();

// nogray.com calendar
// DOCs. http://www.nogray.com/api/calendar.php
(function() {
    $('#generarCalendario').on('click', function(event) {
        event.preventDefault();

        var tipoHorario    = $('#tipoHorario').val();
        var fechaInicio = $('#fechaInicio').val();
        var fechaFin   = $('#fechaFin').val();

        if (fechaInicio == '' || fechaFin == '') {
            alert('Seleccione un intervalo de fechas');
            return;
        }

        $.ajax({
            method: 'POST',
            url: url_visitas_generar_fechas,
            data: { 'tipoHorario': tipoHorario, 'fechaInicio': fechaInicio, 'fechaFin': fechaFin }
        })
        .done(function(data) {
            if (calendario == undefined) {
                setCalendario();
            }

            updateCalendario(data);
            modificar = false;

            $('#calendarioContainer').show();
            $('#generarVisitas').show();
        });
    });

    function setCalendario() {
        calendario = new ng.Calendar({
            input: 'fechas',
            object: 'calendario',
            visible: true,
            num_col: 4,
            start_day: 1,
            date_format: 'Y-m-d',
            multi_selection: true,
            close_on_select: false,
            hide_clear_button: true,
            calendar_img_disabled: true,
            hide_view_all_dates_button: true,
        });

        calendario.p.style_div = ng.create('div', {
            styles:{
                display: 'none'
            }
        });

        calendario.get_object().append_element(calendario.p.style_div);

        ng.get('cal_td' + calendario.get_id()).set_styles({
            '-moz-user-select': '-moz-none',
            '-khtml-user-select': 'none',
            '-webkit-user-select': 'none',
            '-o-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none'
        }).
        add_events({
            'mousedown': function(event) {
                var td = event.src_element;

                if (td.has_class(this.p.css_prefix + 'selectable')) {
                    this.p.mouse_down_date = event.src_element.get('rel');
                }
            }.bind(calendario),
            'mouseup': function(event) {
                var td = event.src_element;

                if ((td.has_class(this.p.css_prefix + 'selectable')) && (ng.defined(this.p.mouse_down_date))) {
                    event.stop();

                    var dt1 = new Date().from_string(this.p.mouse_down_date);
                    var dt2 = new Date().from_string(event.src_element.get('rel'));

                    if (dt1.getTime() > dt2.getTime()) {
                        var dtx = dt2;
                        dt2 = dt1;
                        dt1 = dtx;
                    }

                    var dt1_selected = this.is_selected(dt1);
                    var dt2_selected = this.is_selected(dt2);

                    var arr = [];

                    while (dt1.getTime() <= dt2.getTime()) {
                        arr.push(dt1);
                        dt1 = dt1.clone().from_string('today + 1');
                    }

                    arr.push(dt2);

                    if (dt1_selected && dt2_selected) {
                        this.unselect_date(arr);
                    } else {
                        this.select_date(arr);
                    }
                }

                this.p.mouse_down_date = null;
                this.p.style_div.innerHTML = '';
            }.bind(calendario),
            'mousemove': function(event) {
                var td = event.src_element;
                if ((td.has_class(this.p.css_prefix + 'selectable')) && (ng.defined(this.p.mouse_down_date))) {
                    var dt1 = new Date().from_string(this.p.mouse_down_date);
                    var dt2 = new Date().from_string(event.src_element.get('rel'));

                    if (dt1.getTime() > dt2.getTime()) {
                        var dtx = dt2;
                        dt2 = dt1;
                        dt1 = dtx;
                    }

                    var style = [];
                    while (dt1.getTime() <= dt2.getTime()) {
                        style.push('.ng_cal_selectable.ng_cal_date_' + ((dt1.getMonth() + 1) + '_' + dt1.getDate() + '_' + dt1.getFullYear()));
                        dt1.from_string('today + 1');
                    }

                    style.push('.ng_cal_selectable.ng_cal_date_' + ((dt2.getMonth() + 1) + '_' + dt2.getDate() + '_' + dt2.getFullYear()));
                    this.p.style_div.innerHTML = '<style>' + style.join() + '{ background:#faedd6; border:dotted #999999 1px; }</style>';
                }
            }.bind(calendario),
            'click': function(event) {
                event.stop();
                this.p.style_div.innerHTML = '';
            }
        });
    }

    function updateCalendario(data) {
        calendario.set_num_months(data.numMeses);
        calendario.set_start_date(data.fechaInicio);
        calendario.set_end_date(data.fechaFin);
        calendario.clear_selection();
        calendario.set_dates_off(data.visitas);
        calendario.select_date(data.fechas);
    }
})();


/**********************
 * CALENDARIO VISITAS *
 **********************/

(function() {
    var calendar_render;

    $(document).ready(function() {
        var $calendarHolder = $('#calendar-holder');
        if (0 == $calendarHolder.length) {
            return;
        }

        var days           = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        var daysShort      = ['Do', 'Lu', 'Mar', 'Mi', 'Ju', 'Vi', 'Sa'];
        var months         = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
        var monthsShort    = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Act','Nov','Dic'];
        var $monthSelector = $('#monthSelector');

        $calendarHolder.fullCalendar({
            year: selYear,
            month: selMonth,
            locale: 'es',
            defaultView: 'month',
            firstDay: 1,
            dayNames: days,
            monthNames: months,
            dayNamesShort: daysShort,
            monthNamesShort: monthsShort,
            weekMode: 'variable',
            columnHeader: false,
            header: {
                left: 'prev, next',
                center: 'title',
                right: 'today,'
            },
            eventRender: function(ev, element) {
                var $elementTitle    = element.find('.fc-event-title');
                var event_html_title = $elementTitle.html();

                var event_html_parse = $.parseHTML(event_html_title);
                event_html_parse     = $(event_html_parse).text();

                $elementTitle.html(event_html_parse);
            },
            eventAfterAllRender: function() {},
            dayRender: function(date, cell) {
                var day_week = daysShort[date.getDay()];
                var add_day_number = cell.find('.fc-day-number').prepend('<span class="ra-day-week">' + day_week + '</span>');
            },
            viewRender: function(view, element) {
                var date = $calendarHolder.fullCalendar('getDate');

                $monthSelector.val(date.getFullYear() + '-' + date.getMonth());

                $.ajax({
                    method: 'POST',
                    url: urlCalendarSession,
                    data: {
                        month: date.getMonth(),
                        year: date.getFullYear()
                    },
                    success: function(response) {},
                    error: function(response) {}
                });
            },
            buttonText: {
                'today': 'Hoy'
            },
            eventSources: [{
                url: Routing.generate('fullcalendar_loader'),
                type: 'POST',
                datatype: 'JSON',
                data: {},
                beforeSend: function(data){
                    calendarHolderAddLoading();
                },
                success: function(data) {
                    calendarHolderRemoveLoading();

                    $monthSelector.show();
                },
                error: function() { console.log('Error') }
            }]
        });

        setMonthSelector();
        $monthSelector.change(function(event) {
            var value = $monthSelector.val().split('-');

            $calendarHolder.fullCalendar('gotoDate', value[0], value[1]);
        });

        function setMonthSelector() {
            var today    = new Date(Date()),
                loopDate = new Date(),
                options  = '';

            today.setDate(1);
            loopDate.setTime(today.valueOf());
            loopDate.setDate(1);

            var lastMonth = new Date(today.setMonth(today.getMonth() + 13));

            while (loopDate.valueOf() < lastMonth.valueOf()) {
                var month = loopDate.getMonth();
                var year  = loopDate.getFullYear();

                var selected = (month == selMonth && year == selYear) ? 'selected' : '';

                options += '<option ' + selected + ' value="' + year + '-' + month + '">' + months[month] + ' ' + year + '</option>';

                loopDate.setMonth(loopDate.getMonth() + 1);
            }

            $monthSelector.append(options);
        }
    });

    function parseTitles() {
        var eventos = document.getElementsByClassName('fc-event');

        Array.prototype.forEach.call(eventos, function(el) {
            var color;

            for (var i = 0, l = el.classList.length; i < l; ++i) {
                if (/colorFondo.*/.test(el.classList[i])) {
                    var find_color = el.classList.item(i);
                    color = find_color.split('-')[1];

                    break;
                }
            }

            if (color) {
                var span_color = document.createElement('span');
                span_color.className = 'fc-event-color';
                span_color.style.backgroundColor = color;

                el.appendChild(span_color);
            }
        });
    }

    function calendarHolderAddLoading() {
        $calendar = $('.container-calendarbig');
        $calendar_loading = '<div id="calendar-loading" class="calendar-loading"><div class="calendar-loading-inner"></div></div>';

        $calendar.append($calendar_loading);
    }

    function calendarHolderRemoveLoading() {
        calendarButtonsStyle();

        $('.fc-day-header').html('');

        $calendar_loading = $('#calendar-loading');
        $calendar_loading.fadeOut(1500).promise().done(
            function() {
                parseTitles();
                $(this).remove();
            }
        );
    }

    function calendarButtonsStyle () {
        var $next = $('.fc-button-next');

        if (!$next.hasClass('btn')) {
            $next.find('.fc-text-arrow').remove();
            $next.append('<i class="fas fa-chevron-right"></i>');
            $next.addClass('btn btn-secondary').removeClass('fc-state-default');

            var $previous = $('.fc-button-prev');

            $previous.find('.fc-text-arrow').remove();
            $previous.append('<i class="fas fa-chevron-left"></i>');
            $previous.addClass('btn btn-secondary').removeClass('fc-state-default');

            var $today = $('.fc-button-today');
            $today.append('<i class="far fa-calendar-check fa-lg"></i>');
            $today.addClass('btn btn-secondary').removeClass('fc-state-default fc-state-disabled');
        }
    }
})();

/*****************
 * NUEVA RESERVA *
 *****************/

(function() {
    $(document).ready(function() {
        if (0 == $('#validar').length) {
            return;
        }

        $('#reserva_numeroSocio').keyup(function() {
            calcularImporte();
        });

        $('#reserva_numeroNinos, #reserva_numeroAdultos').change(function() {
            calcularImporte();
        });

        $('#validar').click(function(event) {
            event.preventDefault();

            $('#numero').prop('disabled', true);
            $('#validar').prop('disabled', true);
            $('#valido').css('display', 'none');
            $('#invalido').css('display', 'none');

            var socio = $('#numero').val();
            if (socio == '') {
                $('#numero').prop('disabled', false);
                $('#validar').prop('disabled', false);
                return;
            }

            $.ajax({
                method: 'POST',
                url: url_socio_comprobar,
                data: {
                    socio: socio
                },
                success: function(response) {
                    if (response) {
                        $('#valido').css('display', 'block');
                        $('#reserva_numeroSocio').val(socio);
                        calcularImporte();
                    } else {
                        $('#invalido').css('display', 'block');
                        $('#numero').prop('disabled', false);
                        $('#validar').prop('disabled', false);
                    }
                },
                error: function(response) {}
            });
        });

        $('#reserva_importe').on('change', function(evento) {
            formaPago();
        });

        formaPago();

        function calcularImporte() {
            var socio   = $('#reserva_numeroSocio').val();
            var ninos   = $('#reserva_numeroNinos').val();
            var adultos = $('#reserva_numeroAdultos').val();

            var precioNino = (socio) ? defPrecioSocio : defPrecioNino;
            var precioAdulto = (socio) ? defPrecioSocio : defPrecioAdulto;

            var importe = (ninos * precioNino) + (adultos * precioAdulto);
            $('#reserva_importe').val(importe);

            formaPago();
        }

        function formaPago() {
            var $formaPago = $('#reserva_formaPago');
            var $formaPagoOption = $('#reserva_formaPago option');
            var $formaPagoOption1 = $('#reserva_formaPago option[value="1"]');

            var importe = $('#reserva_importe').val();
            var formaPago = $formaPago.val();

            if (0 == importe) {
                $formaPagoOption.attr('disabled', true);
                $formaPagoOption1.removeAttr('disabled');

                formaPago = '1';
            } else {
                $formaPagoOption.removeAttr('disabled');
                $formaPagoOption1.attr('disabled', true);

                if ('1' == formaPago) {
                    formaPago = '2';
                }
            }

            $formaPago.val(formaPago);
        }
    });
})();


/*************
 * IMPRESIÓN *
 *************/

(function() {
    $(document).ready(function() {
        if (0 == $('#imprimir').length) {
            return;
        }

        var options = {
            viewMode: 'days',
            format: 'DD/MM/YYYY',
            locale: 'es',
            icons: {
                time: 'fa fa-clock',
                date: 'fa fa-calendar-alt',
                up: 'fa fa-chevron-up',
                down: 'fa fa-chevron-down',
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-crosshairs',
                clear: 'fa fa-trash-alt',
                close: 'fa fa-times-circle'
            }
        };

        var $fechaInicio = $('#fechaInicio');
        var $fechaFin    = $('#fechaFin');

        options.minDate = fechaMin;
        options.date = fecha;
        $fechaInicio.datetimepicker(options);

        options.minDate = fecha;
        options.maxDate = fechaMax;
        options.date = fecha;
        $fechaFin.datetimepicker(options);

        $fechaInicio.on('dp.change', function(e) {
            var fecha    = $(this).datetimepicker('date');
            var fechaMax = moment(fecha).add(1, 'week');

            if ($fechaFin.datetimepicker('date').isBefore(fecha)) {
                $fechaFin.data('DateTimePicker').maxDate(fechaMax);
                $fechaFin.data('DateTimePicker').minDate(fecha);
            } else {
                $fechaFin.data('DateTimePicker').minDate(fecha);
                $fechaFin.data('DateTimePicker').maxDate(fechaMax);
            }

            $fechaFin.data('DateTimePicker').date(fecha);
        });
    });
})();
